import React from 'react';
import TestimonialSection from './TestimonialSection';
import StudentListSection from './StudentListSection';
import { TestimonialPageData } from '../../Data/data';
import "./TestimonialPage.css"

const StudentTestimonials = TestimonialPageData.TestimonialStudentData;
const StudentListData = TestimonialPageData.studentData;


export const LogoImageEl = ({url, name}) => {
  return (
    <div
      data-company={name}
      className={`testimonial-company-logo-img ${name?.toLowerCase().replaceAll(" ", "-")}`}
      style={{ backgroundImage: `URL(${url})` }}
    ></div>
  );
};

const TestimonialPage = () => {    
  return (
    <div className="testimonial-page">
      <TestimonialSection testimonials={StudentTestimonials} loadMore={TestimonialPageData.loadMore}/>
      <StudentListSection students={StudentListData} />
    </div>
  );
};

export default TestimonialPage;
