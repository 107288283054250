export const SAP = {
  sapFunctionalModules: [
    {
      id: "1",
      title: "SAP - MM",
      subheading: "(Materials Management)",
      btnLabel: "View Details",
      href: "/sapmm",
      image: "assets/images/coachs/MM2.jpg",
    },
    {
      id: "sd",
      title: "SAP - SD",
      subheading: "(Sales & Distrubution)",
      btnLabel: "View Details",
      href: "/sapsd",
      image: "assets/images/coachs/SD2.jpg",
    },
    {
      id: "2",
      title: "SAP - FICO",
      subheading: "(FI-Financial Accounting and CO-Controlling)",
      btnLabel: "View Details",
      href: "sapfico",
      image: "assets/images/coachs/FICO.jpg",
    },
    {
      id: "3",
      title: "SAP - EWM",
      subheading: "(Extended Warehouse Management)",
      btnLabel: "View Details",
      href: "/sapewm",
      image: "assets/images/coachs/EWM.jpg",
    },
    {
      id: "4",
      title: "SAP - TM",
      subheading: "(Transport Management)",
      btnLabel: "View Details",
      href: "/saptm",
      image: "assets/images/coachs/TM.jpg",
    },
    {
      id: "5",
      title: "SAP - PP",
      subheading: "(Production Planning)",
      btnLabel: "View Details",
      href: "/sappp",
      image: "assets/images/coachs/PP.jpg",
    },
    {
      id: "6",
      title: "SAP - QM",
      subheading: "(Quality Management)",
      btnLabel: "View Details",
      href: "/sapqm",
      image: "assets/images/coachs/QM.jpg",
    },
    {
      id: "7",
      title: "SAP - PM",
      subheading: "(Plant maintenance)",
      btnLabel: "View Details",
      href: "/sapqm",
      image: "assets/images/coachs/PM.jpg",
    },
    {
      id: "8",
      title: "SAP - PS",
      subheading: "(Project System)",
      btnLabel: "View Details",
      href: "/sapps",
      image: "assets/images/coachs/PS.jpg",
    },
    {
      id: "9",
      title: "SAP - HR/HCM",
      subheading: "(Human Resources)",
      btnLabel: "View Details",
      href: "/saphr",
      image: "assets/images/coachs/HR.jpg",
    },
    {
      id: "10",
      title: "SAP - SRM",
      subheading: "(Supplier Relationship Management)",
      btnLabel: "View Details",
      href: "/sapsrm",
      image: "assets/images/coachs/SRM.jpg",
    },
    {
      id: "11",
      title: "SAP - CRM",
      subheading: "(Customer Relationship Management)",
      btnLabel: "View Details",
      href: "/sapcrm",
      image: "assets/images/coachs/CRM.jpg",
    },
    {
      id: "12",
      title: "SAP - PLM",
      subheading: "(Product Lifecycle Management)",
      btnLabel: "View Details",
      href: "/sapplm",
      image: "assets/images/coachs/PLM2.jpg",
    },
    {
      id: "13",
      title: "SAP - ARIBA",
      subheading: "",
      btnLabel: "View Details",
      href: "/sapariba",
      image: "assets/images/coachs/ARIBA.jpg",
    },
  ],

  sapTechnicalModules: [
    {
      id: "1",
      title: "SAP - ABAP",
      subheading: "(Advanced Business Application Programming)",
      btnLabel: "View Details",
      href: "/sapabap",
      image: "assets/images/coachs/ABAP.jpg",
    },
    {
      id: "2",
      title: " SAP - BASIS",
      subheading: "(Business Application Software Integrated Solution)",
      btnLabel: "View Details",
      href: "/sapbasis",
      image: "assets/images/coachs/BASIS.jpg",
    },
    {
      id: "3",
      title: "SAP - SECURITY / GRC",
      subheading: "",
      btnLabel: "View Details",
      href: "/sapsecurity",
      image: "assets/images/coachs/SECURITY.jpg",
    },
    {
      id: "4",
      title: "SAP - BI / BW",
      subheading: "(Business Intelligence/Business Warehouse)",
      btnLabel: "View Details",
      href: "/sapbibw",
      image: "assets/images/coachs/BIBW2.jpg",
    },
    {
      id: "5",
      title: "SAP - BTP",
      subheading: "",
      btnLabel: "View Details",
      href: "/sapbtp",
      image: "assets/images/coachs/btp.jpg",
    },
  ],
};
