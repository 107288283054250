import React from "react";
// import { Link } from "react-router-dom";

export default function Banner(props) {
    return (
        <div>
            {/* <!-- Page Banner Start --> */}
            <section
                className="page-banner-area rel z-1 text-white text-center mt-80"
                style={{ backgroundImage: 'url("assets/' + props.img + '")' }}            
            >
                <div className="container" >
                    <div className="banner-inner rpt-10">
                        <h2 className="page-title ri-color-white ri-banner-page-title">
                            {props.title}
                        </h2>
                        {props.subtitle && <h3 className="page-subTitle">
                            {props.subtitle}
                        </h3>}   

                    </div>
                </div>                
            </section >
            {/* <!-- Page Banner End --> */}
        </div >
    );
}
