import React from "react";

import ModuleCard from "./ModuleCard";

const ModuleTiles = ({ modules }) => {
  return (
    <div>
      <div
        className="row coach-active justify-content-center"
        style={{ width: "100%", margin: "auto" }}
      >
        {modules.map((module) => (
          <ModuleCard module={module} key={module.id}></ModuleCard>
        ))}
      </div>
    </div>
  );
};

export default ModuleTiles;
