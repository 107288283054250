import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "./Home.css";
import { Grid } from "@mui/material";
import "./Othercourses.css";
import { OtherCourses } from "../Data/other-courses-data";

const Othercourses = () => {
  return (
    <div className="container Othercourses">
      <div className="Othercourses-cards-container">
        {OtherCourses.map((otherCourse) => (
          <Card className="Othercourses-card">
            <CardContent className="otherCardHeading">
              {otherCourse.title}
            </CardContent>
            <div className="Othercourses-image-wrapper">
            <CardMedia
              component="img"
              alt="green iguana"              
              image={otherCourse.image}
              className="Othercourses-image"
            />
            </div>
            <CardContent className="otherCardContent1">
              <Typography gutterBottom component="div">
                <p className="Othercourses-descrption">
                  {otherCourse.description}
                </p>
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Othercourses;
