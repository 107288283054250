import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import "./Home.css";

import ModuleTiles from "./ModuleTiles";
import { Data } from "../Data/data";
import { SAP } from "../Data/sap-data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Home() {
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    localStorage.setItem("seenPopUp", true);
    setOpen(false);
  };

  useEffect(() => {
    let returningUser = localStorage.getItem("seenPopUp");
    setOpen(!returningUser);
  }, []);

  window.onbeforeunload = function (e) {
    localStorage.clear();
  };

  return (
    <div>
      <Modal
        open={false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              This is a Pop-Up
            </Typography>
            <p
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </p>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>

      {/* <!-- Features Section Start --> */}
      <section className="features-section rel z-1 pt-80 pb-40 bg-blue text-white">
        <div className="container">
          <div className="row justify-content-center">
            {Data.featuresHighlights.map((featureHighlight) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="feature-item   ">
                    <div className="image">
                      <img src={featureHighlight.image} alt="" />
                    </div>                    
                      <h4 className="make-text-center">
                        {featureHighlight.title}
                      </h4>                    
                  </div>
                </div>
              );
            })}
          </div>
        </div>
                
      </section>
      {/* <!-- Features Section End --> */}

      {/* <!-- About Section Start --> */}
      <section className="about-section pt-130 rpt-100">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={4} style={{ margin: "auto" }}>
            <div className="about-man">
              <img src="assets/images/hero/h2.png" alt="Man" />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{ padding: "3rem", paddingTop: "0rem" }}
          >
            {/* <div className="about-content rel z-2 pb-115 rpb-85   "> */}
            <div
              className="section-title mb-40"
              style={{ textAlign: "left", marginBottom: "3rem" }}
            >
              <h2>Why Choose Rise Infotech?</h2>
            </div>
            <div className="about-features">
              <Grid container spacing={2}>
                {Data.whyChooseOurServiceList.map((whyChooseOurService) => (
                  <Grid item xs={12} md={6} key={whyChooseOurService.id}>
                    <div className="feature-item">
                      <div className="icon">
                        <img src={whyChooseOurService.image} alt="Man" />
                      </div>
                      <h5>{whyChooseOurService.title}</h5>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            {/* </div> */}
          </Grid>
        </Grid>
      </section>
      {/* <!-- About Section End --> */}

      {/* <!-- Coach Section Start --> */}
      <section className="coach-section rel z-1  modules-container rpt-90  rpb-70 bg-lighter">
        <h2 className="ri-dark-heading text-center ri-heading main-title">
          Your Gateway to SAP Proficiency
        </h2>

        {/* <ul className="coach-filter mb-35">
                    <li data-filter="*" className="current">Show All</li> 
                    <li data-filter=".sap">SAP </li>
                    <li data-filter=".salesforce">Sales Force</li>
                </ul> */}

        <div className="sap-functional-module container ri-mb-3">
          <h2 className="text-center">SAP Functional Modules</h2>
          <ModuleTiles modules={SAP.sapFunctionalModules} />
        </div>

        <div className="container">
          <h2 className="text-center">SAP Technical Modules</h2>
          <ModuleTiles modules={SAP.sapTechnicalModules} />
        </div>
      </section>
      {/* <!-- Coach Section End --> */}
    </div>
  );
}
