import { Grid } from "@mui/material";
import React from "react";
import "./CorporateNew.css";

const CorporateNew = () => {
  return (
    <div className="CorporateNew sap-module sap-module-container">
      <header className="sap-module-header">
        <div>
          <p className="sap-module-description">
            In Today’s rapidly evolving business landscape, staying ahead
            requires more than just experience—it demands expertise in the
            latest technologies and business solutions. Our SAP Corporate
            Training program is designed to equip professionals with the
            knowledge and skills needed to leverage SAP software, driving
            efficiency and innovation within your organization.
          </p>
        </div>
        <div>
          <img
            className="sap-module-header-img"
            src="assets/images/coachs/corporate2.jpg"
            alt=""
          />
        </div>
      </header>

      <div>
        <h2 className="ri-page-heading">PROGRAM OVERVIEW</h2>

        <div>
          <div>
            <h2 className="corpSubHeading ri-page-subheading text-center">
              SAP FUNCTIONAL MODULES
            </h2>

            <div className="list-items-grid">
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - MM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - SD
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - FICO
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - EWM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - TM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - PP
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - QM
                </div>
              </div>

              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - PM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - PS
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - HR / HCM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - SRM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - CRM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - PLM
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - ARIBA
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="corpSubHeading ri-page-subheading text-center">
              SAP TECHNICAL MODULES
            </h2>
            <div className="list-items-grid">
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - ABAP
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - BASIS
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - SECURITY / GRC
                </div>
              </div>
              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - BI / BW
                </div>
              </div>

              <div className="card" style={{ border: "None", margin: "8px" }}>
                <div className="card-header" style={{ paddingLeft: "4rem" }}>
                  <img src="/assets/check.png" alt="#" />
                  SAP - BTP
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="line" style={{ width: "100%" }}></div>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <h2 className="corpSubHeading ri-page-heading">TRAINING DELIVERY</h2>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Conduct instructor-led training sessions on various SAP modules (e.g.,
          SAP S/4HANA, SAP FICO, SAP MM, SAP SD, SAP HCM) for corporate clients.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Deliver both in-person and virtual training sessions to accommodate
          client needs.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Provide hands-on exercises, practical examples, and real-world
          scenarios to enhance learning.
        </div>
      </div>

      <div >
        <div className="line" style={{ width: "100%" }}></div>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <h2 className="corpSubHeading ri-page-heading">
          CURRICULUM DEVELOPMENT
        </h2>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Design and develop comprehensive training materials, including
          presentations, manuals, and e-learning modules.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Update training content regularly to reflect the latest SAP updates
          and industry best practices.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Customize training programs to meet the specific needs of different
          clients and industries.
        </div>
      </div>

      <div >
        <div className="line" style={{ width: "100%" }}></div>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <h2 className="corpSubHeading ri-page-heading">
          ASSESSMENT AND EVALUATION
        </h2>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Develop and administer assessments to measure participants'
          understanding and retention of course material.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Provide constructive feedback to participants to help them improve
          their SAP skills.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Monitor and evaluate the effectiveness of training programs and make
          necessary adjustments.
        </div>
      </div>

      <div >
        <div className="line" style={{ width: "100%" }}></div>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <h2 className="corpSubHeading ri-page-heading">CLIENT ENGAGEMENT</h2>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Collaborate with clients to understand their training needs and
          objectives.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Provide pre-training consultations to tailor the training content to
          client requirements.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Offer post-training support and follow-up to ensure successful
          implementation of SAP solutions.
        </div>
      </div>

      <div >
        <div className="line" style={{ width: "100%" }}></div>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <h2 className="corpSubHeading ri-page-heading">
          CONTINUOUS IMPROVEMENT
        </h2>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Stay updated with the latest developments in SAP technologies and best
          practices.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Participate in professional development activities & training to
          enhance personal knowledge & skills.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Share insights and improvements with the training team to enhance
          overall training effectiveness.
        </div>
      </div>

      <div >
        <div className="line" style={{ width: "100%" }}></div>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <h2 className="corpSubHeading ri-page-heading">LEARNING FORMATS</h2>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          In-person training.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Online instructor-led training.
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Self-paced e-learning modules.
        </div>
      </div>

      <div >
        <div className="line" style={{ width: "100%" }}></div>
        <h2 className="corpSubHeading ri-page-heading">KEY BENEFITS</h2>
      </div>
      <div className="container" style={{ marginBottom: "1rem" }}>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Hands-on practical experience
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Access to expert instructors
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Networking opportunities with other professionals
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Preparation for SAP certification exams
        </div>
        <div className="card-header" style={{ alignItems: "flex-start" }}>
          <img src="/assets/check.png" alt="#" style={{ marginTop: "10px" }} />
          Customized corporate training options
        </div>
      </div>
    </div>
  );
};

export default CorporateNew;
