export const OtherCourses  = [
    {
        title: "MS OFFICE SUITES",
        image: "assets/images/coachs/other1.jpg",
        description : "Proficiency in Microsoft Office, Google Workspace, and equivalent productivity tools"
    },
    {
        title: "COMMUNICATION",
        image: "assets/images/coachs/other2.jpg",
        description : "Strong written and verbal communication skills to effectively convey technical information to non-technical stakeholders"
    },
    {
        title: "PUBLIC SPEAKING",
        image: "assets/images/coachs/other3.jpg",
        description : "Gain confidence in presenting ideas and solutions in meetings, conferences, and client interactions"
    },
    {
        title: "EMAIL WRITING SKILLS",
        image: "assets/images/coachs/other4.jpg",
        description : "Effective email communication is crucial for IT professionals, as it helps convey technical information clearly, maintain professional relationships, and ensure smooth project coordination"
    },
    {
        title: "PERSONALITY DEVELOPMENT",
        image: "assets/images/coachs/other5.jpg",
        description : "Personality development in professionals is crucial for their overall career growth and success. It encompasses the enhancement of various personal traits, skills, and behaviours that contribute to professional effectiveness, interpersonal relationships, and personal well-being"
    },
]