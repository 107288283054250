import React from "react";
import './WhatsApp.css';
import ReactWhatsapp from "react-whatsapp";

export default function WhatsApp() {
    return (
      <div>
        <ReactWhatsapp className='whatsapp_float' number='+91 9110 455 125' message='I would like to join course'><i className="fa-brands fa-whatsapp"></i></ReactWhatsapp>
      </div>
  );
}