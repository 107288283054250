import "./TestimonialSection.css";
import React, { useState } from "react";
import { LogoImageEl } from "./TestmonialPage";
const QuoteText = ({ text, className }) => {
  return (
    <p className={className}>
      <span className="ri-quote"></span>
      {text}
    </p>
  );
};

const TestimonialSection = ({ testimonials, loadMore }) => {
  const [visibleCards, setVisibleCards] = useState(6);
  const handleLoadMore = () => {
    setVisibleCards(testimonials.length);
  };
  return (
    <div className="testimonial-section container">
      <h2 className="ri-section-title">Hear from Our Students!</h2>
      <div className="testimonials">
        {testimonials.slice(0, visibleCards).map((testimonial, index) => (
          <div key={index} className="testimonial paper">
            <div className="testimonial-content ri-relative">
              <QuoteText
                text={testimonial.text}
                className={"ri-color-black"}
              ></QuoteText>
              <div>
                <h4 className="ri-text-color-primary m-0 testimonial-content-name">
                  {testimonial.name}
                </h4>
                <div className="company-info">
                  {/* <span className="ri-text-color-primary">
                  {testimonial.company}
                </span> */}
                  <span>
                    {/* <img
                      className={testimonial.company.toLowerCase().replaceAll(" ", "-")}
                      src={testimonial.companyLogo}
                      alt={`${testimonial.company} logo`}
                    /> */}
                    <LogoImageEl url={testimonial.companyLogo} name={testimonial.company}/>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {visibleCards < testimonials.length && (
        <div className="load-more">
          <button className="theme-btn" onClick={handleLoadMore}>
            {loadMore}
          </button>
        </div>
      )}
    </div>
  );
};

export default TestimonialSection;
