import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export default function Mailer() {
  const form = useRef();
  const [showDiv, setShowDiv] = useState(true);
  const [phone, setPhone] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    text: ""
  });

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      name: form.current.name.value,
      email: form.current.email.value,
      phone: phone,
      message: form.current.message.value,
    }

    emailjs.send(
      "service_p73u4n2",
      "template_chunune",
      // e.target,
      templateParams,
      "0McgHGFaHXgjLm9mQ"
    ).then((result) => {
      e.target.reset();
      setPhone('');
      console.log('Sent Successfully');
      setResponse(result);
      setFormSubmitted(true);

    })
      .catch((error) => {
        console.log('Error Sending Email', error);
      });
  }

  useEffect(() => {
    if (formSubmitted) {
      const timer = setTimeout(() => {
        setShowDiv(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [formSubmitted]);

  return (
    <div>
      <div class="appointment-form">
        <form ref={form} onSubmit={sendEmail}>
          <div classNAme="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              required="*"
            />
          </div>
          <div classNAme="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              required="*"
            />
          </div>
          {/* <div class="form-group">
            <input
              type="number"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              required="*"
              maxLength="10"
            />
          </div> */}
          <div class="form-group">
            <PhoneInput
              country={'in'}
              value={phone}
              onChange={(value, data, event, formattedValue) => setPhone(formattedValue)}
              // className='countryCode'
              inputStyle={{
                display: 'flex', alignItems: 'flex-end', color: '#ffffff', fontSize: '1rem', background: 'none', width: '100%', padding: '21.5px 20px 21.5px 50px', border: '1px solid rgba(255, 255, 255, 0.1)'
              }}
              buttonStyle={{ color: '#ffffff', fontSize: '1rem', background: 'none', border: '1px solid rgba(255, 255, 255, 0.1)' }}
              dropdownStyle={{ color: 'black' }}
            />
          </div>

          <div className="form-group">
            <textarea name="message" id="message" placeholder="Message" rows="5"></textarea>
          </div>
          <div className="form-group">
            <button id="send" type='submit' class="theme-btn" >
              Submit now
            </button>
          </div>

          {showDiv && response.status === 200 && <div className="alert alert-success" role="alert">
            Your Message Successfully sent
          </div>}

          {showDiv && response.status === 400 && <div class="alert alert-danger" role="alert">
            Error, Try again.
          </div>}

        </form>
      </div>
    </div>
  );
}
