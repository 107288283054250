import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";


import Navbar from "./components/navbar/Navbar";
import HiddenSlider from "./components/HiddenSlider";
import Slider from "./components/Slider";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import About from "./components/About";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";
import TestimonialPage from "./components/Testimonial/TestmonialPage";
import ScrollToTop from "./components/ScrollToTop";
import Functional from './components/Functional';
import Technical from './components/Technical';
// import Sapmm from './components/Courses/Sapmm-removed';
import SAPModulePage from "./components/Templates/SAPModulePage";
import { ModulesPageData } from "./Data/sap-modules-page-data";


// import Sapfico from './components/Courses/Sapfico-removed';
// import Sapsd from './components/Courses/Sapsd-removed';
// import Sapewm from './components/Courses/Sapewm';
// import Saptm from './components/Courses/Saptm';
// import Sappp from './components/Courses/Sappp';
// import Sapqm from './components/Courses/Sapqm';
// import Sappm from './components/Courses/Sappm';
// import Sapps from './components/Courses/Sapps';
// import Saphr from './components/Courses/Saphr';
// import Sapsrm from './components/Courses/Sapsrm';
// import Sapcrm from './components/Courses/Sapcrm';
// import Sapplm from './components/Courses/Sapplm';
// import Sapabap from './components/Courses/Sapabap';
// import Sapariba from './components/Courses/Sapariba';
// import Sapbasis from './components/Courses/Sapbasis';
// import Sapsecurity from './components/Courses/Sapsecurity';
// import Sapbibw from './components/Courses/Sapbibw';
import Othercourses from './components/Othercourses';
import CorporateNew from './components/CorporateNew';
import { TestimonialPageData } from "./Data/data";


function App() {
  return (
    <div>

      <Helmet>
        <title>Rise Infotech | Home </title>
        <meta name="description" content="Riseinfotech is a corporate offering wide range of corporate Training and Consultations" />
        <meta name="keywords" content="sap, salesforces" />
      </Helmet>

      <Router>
        <ScrollToTop />
        <Navbar />
        <HiddenSlider />


        <Routes>

          <Route
            path="/about"
            element={
              <>
                <Banner title="About Us" img="images/banner_2.png" />
                <About />
              </>
            }
          ></Route>

          <Route
            path="/technical"
            element={
              <>
                <Banner title="SAP Technical Modules" img="images/banner_2.png" />
                <Technical />
              </>
            }
          ></Route>

          <Route
            path="/functional"
            element={
              <>
                <Banner title="SAP Functional Module" img="images/banner_2.png" />
                <Functional />
              </>
            }
          ></Route>

          <Route
            path="/corporate"
            element={
              <>
                <Banner title="Corporate Training" img="images/banner_2.png" />
                <CorporateNew />
              </>
            }
          ></Route>

          <Route
            path="/othercourses"
            element={
              <>
                <Banner title="Other Courses" img="images/banner_2.png" />
                <Othercourses />
              </>
            }
          ></Route>

          <Route
            path="/contact"
            element={
              <>
                <Banner title="Contact Us" img="images/banner_2.png" />
                <Contact />
              </>
            }
          ></Route>

          <Route
            path="/testimonials"
            element={
              <>
                <Banner title={TestimonialPageData.title} img="images/banner_2.png" />

                <TestimonialPage />
              </>
            }
          ></Route>

            {
              ModulesPageData.map(ModulePageData=>{
                return (
                  <Route
                  path={ModulePageData.path}
                  element={
                    <>
                      <Banner title={ModulePageData.heading} img="images/banner_2.png" />
                      <SAPModulePage page={ModulePageData}/>
                    </>
                  }
                ></Route>
                )
              })
            }               

          <Route
            path="*"
            element={
              <>
                <Banner title="404 Page Not Found" />
              </>
            }
          ></Route>

          <Route
            path="/"
            element={
              <>
                <Slider />
                <Home />
              </>
            }
          ></Route>

        </Routes>

        <Footer />
      </Router>

    </div>
  );
}

export default App;
