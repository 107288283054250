import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Slider() {
  return (
    <>
      {/* <!-- Hero Section Start --> */}
      <section className="hero-section rel z-1 pt-100 pb-75 rpb-100">
        <div>
          <Grid container justifyContent="center" alignItems="flex-start">
            <Grid
              item
              xs={12}
              md={7}
              style={{ margin: "auto", padding: "2.2rem" }}
            >
              <div className="hero-content rpt-10 rmb-10">
                <h1 className="mb-20 main-title">
                  Achieve Excellent SAP Skills for the Modern Professional
                </h1>
                <p>
                  Rise Infotech is a premier destination for SAP training and
                  certification. As a leader in SAP education, we are dedicated
                  to empowering individuals and organizations with the skills
                  and knowledge needed to excel in the dynamic world of
                  enterprise resource planning (ERP)
                </p>
                <div className="hero-btn mt-30">
                  <Link to="/contact" className="theme-btn">
                    Talk to Us<i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className="heroImage ri-hide-mobile">
              <img src="assets/images/hero/3.jpg" alt="hero" />
              {/* <div className="hero-right-images text-lg-right   ">
                            </div> */}
            </Grid>
          </Grid>
        </div>
      </section>
      {/* <!-- Hero Section End --> */}
    </>
  );
}
