import React from "react";
import "./StudentListSection.css";
import { LogoImageEl } from "./TestmonialPage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StudentListSection = ({ students }) => {
  const settings = {
    dots: false, // Hide the dots
    infinite: true, // Enable continuous loop  
    speed: 4000, 
    slidesToShow: 7, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 0, // Continuous scrolling without delay
    cssEase: "linear", // Linear animation for continuous loop
    pauseOnHover: true, // Continue sliding on hover
    responsive: [      
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.5,          
        },
      },     
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,         
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,         
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,         
        },
      },
      {
        breakpoint: 250,
        settings: {
          slidesToShow: 1,         
        },
      },
    ],
  };

  return (
    <div className="student-list-section">
      <h2 className="ri-section-title text-center">Our Achievements</h2>
      <h2 className="ri-section-title-2 text-center">Our Students are Placed in following companies</h2>
      <div className="student-list-container">
        <Slider {...settings}>
          {students.map((student, index) => (
            <div className="student-list text-center" key={index}>
              <div className="student-list-content">
              <div className="student-name">{student.name}</div>
              <div className="company-img-container">
                {/* <img
                  src={student.companyLogo}
                  alt={`${student.company} logo`}
                  className="company-img"
                /> */}
                <LogoImageEl url={student.companyLogo} name={student.company}/>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default StudentListSection;
