import { Grid } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import "../Templates/Sap.css";

const SAPModulePage = ({ page }) => {
  const hasSecondColumn = page.secondColumnData ? true : false;

  return (
    <div className="sap-module sap-module-container">
      <Helmet>
        <title>{page.title}</title>
        <meta
          name="description"
          content="Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing."
        />
        <meta
          name="keywords"
          content="SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling"
        />
        <link rel="canonical" href="/sapmm" />
      </Helmet>

      <section className="course-details-area">
        <header className="sap-module-header">
          <div>
            <h2 className="sap-module-heading">{page.whatIsModuleTitle}</h2>
            <p className="sap-module-description">
              {page.whatIsModuleDescription}
            </p>
          </div>
          <div>
            <img
              className="sap-module-header-img"
              src={page.whatsIsModuleImage}
              alt=""
            />
          </div>
        </header>

        <div className="course-details-content">
          <Grid item xs={12} md={4} className="sap-module-section">
            <div>
              <div>
                <h2 className="sap-module-heading">Who can learn ?</h2>
              </div>
              <div className="sap-module-faq-accordion" id="course-faq">
                {page.faq.map((item, index) => (
                  <div className="card" key={index}>
                    <div className="card-header">
                      <img src="/assets/bullet.png" alt="#" />
                      {item}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>

          {/* <div className="line" style={{ marginTop: '0px' }}></div> */}

          <div className="sap-module-what-learn sap-module-section">
            <div>
              <div>
                <h2 className="sap-module-heading">What will you learn ?</h2>
              </div>
            </div>
            <div>
              <div
                className="sap-module-what-learn-item-content"
                data-hasSecondColumn={hasSecondColumn}
              >
                <div className="what-you-learn-card-container">
                  {page?.firstColumnData?.map((item, index) => (
                    <div>
                      <div
                        className="card"
                        key={index}                       
                      >
                        <div className="card-header"  style={item.subItems ? { color: "#0769b4" } : null}>
                          <img
                            className="sap-module-dot-style"
                            src="/assets/check.png"
                            alt="#"
                          />
                          {item?.title || item}
                        </div>
                      </div>
                      {item.subItems &&
                        item.subItems.map((subItem) => (
                          <div className="card" key={index}>
                            <div className="subcard-header">
                              <img
                                className="sap-module-dot-style"
                                src="/assets/bullet.png"
                                alt="#"
                              />
                              {subItem}
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>

                {hasSecondColumn && (
                  <div>
                    {page.secondColumnData.map((item, index) => (
                      <div className="card" key={index}>
                        <div
                          className={
                            item.subcard ? "subcard-header" : "card-header"
                          }
                          style={item.style || {}}
                        >
                          <img
                            className="sap-module-dot-style"
                            src={
                              item.subcard
                                ? "/assets/bullet.png"
                                : "/assets/check.png"
                            }
                            alt=""
                          />
                          {item.title || item}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </div>
  );
};

export default SAPModulePage;
