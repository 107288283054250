import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import { Link } from "react-router-dom";

const ModuleCard = ({ module }) => {
  return (
    <div className="col-lg-4 col-md-6 item sap photography">
      <Card
        className="module-card"
        sx={{ maxWidth: 370 }}
        style={{ margin: "1.5rem 0.5rem" }}
      >
        <CardMedia component="img" alt="" height="210" image={module.image} />
        <CardContent style={{ height: "10rem" }}>
          <Typography gutterBottom variant="h5" component="div">
            <h3
              className="title"
              style={{ marginBottom: "0px", textAlign: "center" }}
            >
              {module.title}
            </h3>
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <h4 className="subheading">{module.subheading}</h4>
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Link
            aria-label={module.title}
            to={module.href}
            className="category ri-button-outline"
          >
            {module.btnLabel}
          </Link>
        </CardActions>
      </Card>
    </div>
  );
};

ModuleCard.propTypes = {
  module: PropTypes.shape({
    image: PropTypes.string.isRequired,
    alt: PropTypes.string,
    title: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    btnLabel: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default ModuleCard;
