import React from 'react';

import Mailer from './Mailer';

export default function HiddenSlider() {
  return (
    <div>
      {/* <!-- Hidden Sidebar --> */}
      <section className="hidden-bar">
        <div className="inner-box text-center">
          <div className="cross-icon"><span className="fa fa-times"></span></div>
          <div className="title">
            <h4>Get in Touch</h4>
          </div>

          <Mailer />

          {/* <!--Social Icons--> */}
          <div className="social-style-one">
            <a id='facebook'
              href="https://www.facebook.com/Rise-Infotech-100291029634133/
"
              target="_blank"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a id='twitter' href="https://twitter.com/RiseInfotech" target="_blank">
              <i className="fab fa-twitter"></i>
            </a>
            <a id='linkedin'
              href="https://www.linkedin.com/company/rise-infotech/"
              target="_blank"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a id='instagram'
              href="https://www.instagram.com/rise_infotech/"
              target="_blank"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a id='youtube'
              href="https://www.youtube.com/@riseinfotech2023"
              target="_blank"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
          </div>
        </div>
      </section>
      {/* <!--End Hidden Sidebar --> */}
    </div>
  );
}
