import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Data, FooterData } from "../Data/data";

export default function Footer() {
  return (
    <footer className="main-footer ri-main-footer bg-blue text-white">
      <div className="footer-flex-items">
        <div className="footer-about-us-content footer-item">
          <div className="footer-widget about-widget">
            <h5 className="footer-title">About Us</h5>
            <p>{FooterData.AboutUs}</p>
          </div>
        </div>
        <div className="footer-flex-item footer-widget footer-item">
          <div className="menu-widget">
            <h5 className="footer-title">SAP ERP Courses</h5>
            <ul style={{ marginBottom: "25px" }}>
              <li>
                <Link to="/functional" className="ri-link">
                  SAP Functional Modules
                </Link>
              </li>
              <li>
                <Link to="/technical" className="ri-link">
                  SAP Technical Modules
                </Link>
              </li>
            </ul>
          </div>
          <h5 className="footer-title">
            <Link to="/othercourses" className="ri-link">
              Other Courses
            </Link>
          </h5>
        </div>
        <div className="footer-flex-item footer-widget footer-item">
          <div className="contact-info-widget">
            <h5 className="footer-title">Get In Touch</h5>
            <ul>
              {/* <li>
                    <i className="fas fa-map-marker-alt"></i> 55 Main Street,
                    2nd Block, New York
                  </li> */}
              <li>
                <i className="far fa-envelope"></i>{" "}
                <a className="footer-contact-item" href={`mailto:${Data.contactInfo.email}`}>
                  {Data.contactInfo.email}
                </a>
              </li>
              <li>
                <i className="fas fa-phone"></i>{" "}
                <a  className="footer-contact-item" href={`tel:${Data.contactInfo.phone}`}>
                  {Data.contactInfo.displayPhone}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h5>{FooterData.FollowUsLabel}</h5>
            <div className="social-style-one">
              {Data.socialMediaList.map((socialMedia) => {
                return (
                  <a
                    id={socialMedia.id}
                    href={socialMedia.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={socialMedia.image}
                      alt="instagram"
                      className="squareLogo"
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area bg-dark-blue rel">
        <div className="container">
          <div className="copyright-inner">
            <p>
              © 2022. <Link to="/">RISE INFOTECH</Link> All rights reserved.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Scroll Top Button --> */}
      <button className="scroll-top scroll-to-target" data-target="html">
        <span className="fas fa-angle-double-up"></span>
      </button>
    </footer>
  );
}
