import React, { useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import WhatsApp from "../whatsapp/WhatsApp";
import { Data } from "../../Data/data";

const Navbar = () => {
  const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener("resize", showButton);

  let activeStyle = {
    // textDecoration: "underline",
    color: "#0769B4",
  };

  // let activeClassName = "underline";

  return (
    <>
      <div div className="header-top bg-light-blue text-white">
        <div className="container">
          <div className="top-inner">
            <div className="top-left d-flex align-items-center" style={{columnGap:"var(--spacing-4)"}}>
              <p class="contact-phone">
                <i className="fas fa-phone"></i>{" "}
                <a
                  href={`tel:${Data.contactInfo.phone}`}
                  className="contact-phone"
                >
                  {Data.contactInfo.displayPhone}
                </a>{" "}
              </p>
              <p class="contact-email">
                <i className="fas fa-envelope"></i>{" "}
                <a href="mailto:info@riseinfotech.in">
                  {Data.contactInfo.email}
                </a>{" "}
              </p>
              <div className="menu-sidebar ri-hide-mobile">
                <button className="ri-button-outline-secondary">Enquiry</button>
              </div>
            </div>
         

            <div className="d-flex">
              <div className="d-flex align-items-center">
                <div className="social-style-one">
                  {Data.socialMediaList.map((socialMedia) => {
                    return (
                      <a
                        id={socialMedia.id}
                        href={socialMedia.url}
                        target="_blank"
                        rel="noreferrer"
                        className="social-media-icon-link"
                      >
                        <img
                          src={socialMedia.image}
                          alt={socialMedia.id}
                          className="squareLogo"
                        />
                      </a>
                    );
                  })}
                    <div className="menu-sidebar ri-hide-desktop">
                <button className="ri-button-outline-secondary">Enquiry</button>
              </div>
          </div>
                </div>
              </div>
            </div>          
        </div>
      </div>
      <div>
        <header className="main-header">
          <div className="header-upper bg-white">
            <nav className="main-menu navbar-expand-lg">
              <div className="navbar-container  container-fluid">
                <div className="logo-outer d-lg-flex align-items-center">
                  <div className="logo">
                    <NavLink to="/" onClick={closeMobileMenu}>
                      <img
                        src="assets/images/logos/logo2.jpg"
                        alt="Logo"
                        title="Logo"
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="menu-icon" onClick={handleClick}>
                  {click ? <FaTimes /> : <FaBars />}
                </div>
                <div className={`navbar-collapse menu-navbar-collapse ${click ? "active" : ""}`}>
                  <ul
                    className={
                      click
                        ? "nav-menu active navigation clearfix menu-nav-menu"
                        : "nav-menu navigation clearfix "
                    }
                  >
                    <li className="nav-item">
                      <NavLink
                        to="/about"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        About US
                      </NavLink>
                    </li>
                    <li className="dropdown">
                      <NavLink to="#" className="nav-links">
                        SAP ERP Courses
                      </NavLink>
                      <ul style={{ top: "95%", height: "unset"}}>
                        <li
                          className="dropdown"
                          style={{ paddingRight: "0.4rem" }}
                        >
                          <NavLink
                            to="#"
                            className="nav-links"
                            style={{ padding: "0.5rem 0.2rem" }}
                          >
                            SAP Functional Modules
                          </NavLink>
                          <ul className="dropdwonHeader">
                            <li className="nav-item">
                              <NavLink
                                to="/sapmm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - MM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapsd"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - SD
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapfico"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - FICO
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapewm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - EWM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/saptm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - TM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sappp"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - PP
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapqm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - QM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sappm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - PM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapps"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - PS
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/saphr"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - HR / HCM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapsrm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - SRM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapcrm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - CRM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapplm"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - PLM
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapariba"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - ARIBA
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li
                          className="dropdown"
                          style={{ paddingRight: "0.4rem" }}
                        >
                          <NavLink
                            to="#"
                            className="nav-links"
                            style={{ padding: "0.5rem 0.2rem" }}
                          >
                            SAP Technical Modules
                          </NavLink>
                          <ul>
                            <li className="nav-item">
                              <NavLink
                                to="/sapabap"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - ABAP
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapbasis"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - BASIS
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapsecurity"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - SECURITY / GRC
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapbibw"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - BI / BW
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/sapbtp"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                className="nav-links"
                                onClick={closeMobileMenu}
                              >
                                SAP - BTP
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/othercourses"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        Other Courses
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/corporate"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        Corporate
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/testimonials"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        Testimonials
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/contact"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        Contact us
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <WhatsApp />
        </header>
      </div>
    </>
  );
};

export default Navbar;
