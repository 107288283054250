import { Grid } from "@mui/material";
import React from "react";

import { Helmet } from "react-helmet";
import { AboutUs } from "../Data/about-us-page-data";
import "./aboutUs.css";

export default function About() {
  return (
    <div className="ri-page ri-page-container">
      <Helmet>
        <title>Rise Infotech | About Us </title>
        <meta
          name="description"
          content="Rise Infotech is a leading institute in bangalore conducting training programs for various software fields & providing certification for both individuals and organizations."
        />
        <meta name="keywords" content="sap, salesforce" />
        <meta rel="canonical" href="/about" />
      </Helmet>

      {/* <!-- About Section Start --> */}
      <section className="about-page-section pb-10 pt-10 rpt-20">
        <header className="ri-page-header">
          <div>
            <h2 className="ri-page-heading">{AboutUs.heading}</h2>
            <p className="ri-page-description">{AboutUs.content}</p>
            <div className="about-us-author">
              <span className="about-us-author-line-1">
                {AboutUs.authorName}
              </span>
              <span className="about-us-author-line-2">
                {AboutUs.authorRole}
              </span>
            </div>
          </div>
          <div>
            <img className="ri-page-image" src={AboutUs.image} alt="" />
          </div>
        </header>
      </section>
      {/* <!-- About Section End --> */}

      {/* <!-- Why Learn Start --> */}
      <section className="why-learn-area rel z-1  pb-20 rpb-15">
        <Grid
          container
          justifyContent="center"          
        >
          <Grid item xs={12} md={4} style={{ padding: "1rem" }}>
            <div className="why-learn-content rmb-35">
              <div className="feature-three-item about-us-card-item">
                <div className="icon">
                  <img src="assets/images/features/target.png" alt="Icon" />
                  <h3>Our Mission</h3>
                </div>
                <p>
                  At Rise Infotech, our mission is to provide top-tier SAP
                  training that equips our students with the skills and
                  knowledge needed to excel in the dynamic world of enterprise
                  resource planning (ERP). We strive to create a learning
                  environment that fosters innovation, critical thinking, and
                  practical expertise.
                </p>
                {/* <Link to="/about" className="read-more color-two">
                    Read more <i className="fas fa-arrow-right"></i>
                  </Link> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "1rem" }}>
            <div className="why-learn-content rmb-10">
              <div className="feature-three-item about-us-card-item">
                <div className="icon">
                  <img src="assets/images/features/eye.png" alt="Icon" />
                  <h3>Our Vision</h3>
                </div>
                <p>
                  We envision a future where every professional is empowered
                  with cutting-edge SAP skills, enabling them to contribute
                  effectively to their organizations and the global economy.
                  Through our comprehensive training programs, we aim to be the
                  premier destination for SAP education and a catalyst for
                  career advancement.
                </p>
                {/* <Link to="/about" className="read-more color-two">
                  Read more <i className="fas fa-arrow-right"></i>
                </Link> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "1rem" }}>
            <div className="why-learn-content rmb-35">
              <div className="feature-three-item about-us-card-item">
                <div className="icon">
                  <img
                    src="assets/images/features/relationship.png"
                    alt="Icon"
                  />
                  <h3>Our Commitment</h3>
                </div>
                <p>
                  At Rise Infotech, we are dedicated to delivering excellence in
                  every aspect of our training. We take pride in our students'
                  success and are committed to supporting them at every step of
                  their journey. Our personalized approach to education ensures
                  that each student receives the attention and resources they
                  need to thrive.
                </p>
                {/* <Link to="/about" className="read-more color-two">
                  Read more <i className="fas fa-arrow-right"></i>
                </Link> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      {/* <!-- Why Learn End --> */}
    </div>
  );
}
