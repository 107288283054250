import { SAP } from "./sap-data";
export const ModulesPageData = [
  {
    path: "/sapmm",
    title: "Rise Infotech | SAP MM ",
    heading: `${SAP.sapFunctionalModules[0].title} ${SAP.sapFunctionalModules[0].subheading} `,
    whatIsModuleTitle: "What is SAP-MM ? ",
    whatsIsModuleImage: "/assets/images/coachs/mmPage.jpg",
    whatIsModuleDescription: `SAP Materials Management (MM) is a module within the SAP ERP
    (Enterprise Resource Planning) system that focuses on the
    efficient and effective management of materials within an
    organization. It plays a crucial role in the logistics and supply
    chain functions, encompassing a range of processes from
    procurement to inventory management.`,
    faq: [
      "Supply Chain and Logistics Professionals",
      "Procurement Officers and Managers",
      "Inventory Managers",
      "Production Planners",
      "IT Professionals and SAP Consultants",
      "Finance and Accounting Professionals",
      "Career Changers",
      "Students and Graduates",
    ],
    firstColumnData: [
      "SAP ERP Overview",
      "SAP Architecture",
      "SAP Functional and Technical modules overview",
      "Supply Chain Management and Logistics Overview",
      "Purchasing and Procurement process",
      "SAP System landscape",
      "Enterprise Structure",
      "List of Master data with configurations",
      "Standard Business Process with configurations",
      "Pricing Procedure",
      "Special procurements Business Process",
      "Outline Agreements with configurations",
      "MRP (Material Requirements Planning) Overview",
      "Physical Inventory",
      "Batch Management Process",
      "Stock Transport Order (STO)",
    ],

    secondColumnData: [
      "Automatic Account Determination (MM-FI)",
      "PO Output Determination",
      "SAP S4 HANA and Fiori Application",
      "SAP ECC vs SAP S4 HANA",
      "SPRO Path Vs Easy Access Path",
      {
        title: "Real time Project Specific",
        style: { color: "#0769b4" },
      },
      {
        title: "List of SAP Projects overview and Introduction",
        subcard: true,
      },
      {
        title: "Project Methodology",
        subcard: true,
      },
      {
        title: "TR movements",
        subcard: true,
      },
      {
        title: "Different types of Testing",
        subcard: true,
      },
      {
        title: "Prepare and Execute Test Scripts",
        subcard: true,
      },
      {
        title: "Interfaces",
        subcard: true,
      },
      {
        title: "Enhancements",
        subcard: true,
      },
      {
        title: "RICEFW",
        subcard: true,
      },
      {
        title: "Technical terminologies",
        subcard: true,
      },
    ],
  },
  {
    path: "/sapsd",
    title: "Rise Infotech | SAP SD",
    heading: `${SAP.sapFunctionalModules[1].title} ${SAP.sapFunctionalModules[1].subheading} `,
    whatIsModuleTitle: "What is SAP-SD ? ",
    whatsIsModuleImage: "/assets/images/coachs/sdPage.jpg",
    whatIsModuleDescription: `SAP Sales and Distribution (SD) is a core module within the SAP ERP system that handles all processes related to order-to-cash (OTC) cycle, encompassing sales, distribution, and billing. It is designed to facilitate efficient management of the sales and distribution activities of an organization`,
    faq: [
      "Sales and Marketing Professionals",
      "Distribution and Logistics Managers",
      "IT Professionals and SAP Consultants",
      "Finance and Accounting Professionals",
      "Students and Graduates",
    ],
    firstColumnData: [
      "SAP ERP Overview",
      "SAP -SD module Introduction",
      "SAP System landscape",
      "SAP Architecture",
      "SAP Functional and Technical modules overview",
      "SAP-SD Enterprise Structure",
      "List of Master data with configurations",
      "Standard Business Process with configurations",
      "Special Business Process with configurations",
      "Sales documents and controls",
      "Pricing Procedure",
      "Basic functions",
      "Credit management",
      "Output determinations",
    ],

    secondColumnData: [
      "Copy controls",
      "Cross functional module integration with MM, FICO and PP modules",
      {
        title: "Real time Project Specific",
        style: { color: "#0769b4" },
      },
      {
        title: "List of SAP Projects overview and Introduction",
        subcard: true,
      },
      { title: "SAP-SD Configurations", subcard: true },
      { title: "Project Methodology", subcard: true },
      { title: "TR movements", subcard: true },
      { title: "Different types of Testing", subcard: true },
      { title: "Prepare and Execute Test Scripts", subcard: true },
      { title: "Interfaces", subcard: true },
      { title: "Enhancements", subcard: true },
      { title: "RICEFW", subcard: true },
      { title: "SAP S4 HANA and Fiori app overview", subcard: true },
      { title: "Technical terminologies", subcard: true },
    ],
  },
  {
    path: "/sapfico",
    title: "Rise Infotech | SAP FICO",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords: [
      "SAP Materials management",
      "SAP Warehouse management",
      "SAP Advance business application programming",
      "SAP Extended Warehouse management",
      "SAP Finance and Controlling",
    ],
    heading: `${SAP.sapFunctionalModules[2].title} ${SAP.sapFunctionalModules[2].subheading} `,
    whatIsModuleTitle: "What is SAP-FICO ? ",
    whatsIsModuleImage: "/assets/images/coachs/ficoPage.jpg",
    whatIsModuleDescription: `SAP Financial Accounting and Controlling (FICO) is one of the most important modules within the SAP ERP system. It is designed to meet all the accounting and financial needs of an organization.`,
    faq: [
      "Finance and Accounting Professionals",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "SAP ERP Overview",
      "SAP Architecture",
      "SAP Functional and Technical modules overview",
      "SAP -FICO Introduction",
      "SAP System landscape",
      "Enterprise Structure",
      "General Ledger (GL) Accounting",
      "Accounts Payable (AP) and Accounts receivable (AR)",
      "Asset Accounting/Bank Accounting",
      "Cost Element Accounting/ Cost Centre Accounting/ Profit Centre Accounting",
      "Product Cost Controlling/ Profitability Analysis (CO-PA)",
    ],

    secondColumnData: [
      "Cross functional Module integration with MM, SD, PP, PM, EWM",
      "SAP S4 HANA and Fiori Application Overview",
      {
        title: "Real time Project Specific",
        style: { color: "#0769b4" },
      },
      {
        title: "List of SAP Projects overview and Introduction",
        subcard: true,
      },
      {
        title: "List of SAP Projects overview and Introduction",
        subcard: true,
      },
      {
        title: "Project Methodology",
        subcard: true,
      },
      {
        title: "TR movements",
        subcard: true,
      },
      {
        title: "Different types of Testing",
        subcard: true,
      },
      {
        title: "Prepare and Execute Test Scripts",
        subcard: true,
      },
      {
        title: "Interfaces",
        subcard: true,
      },
      {
        title: "Enhancements",
        subcard: true,
      },
      {
        title: "Technical terminologies",
        subcard: true,
      },
    ],
  },
  {
    path: "/sapewm",
    title: "Rise Infotech | SAP EWM",
    description:
      "It consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling.",
    heading: `${SAP.sapFunctionalModules[3].title} ${SAP.sapFunctionalModules[3].subheading} `,
    whatIsModuleTitle: "What is SAP-EWM ? ",
    whatsIsModuleImage: "/assets/images/coachs/ficoPage.jpg",
    whatIsModuleDescription: `SAP Extended Warehouse Management (EWM) is a comprehensive warehouse management solution integrated within the SAP Supply Chain Management (SCM) suite. It is designed to optimize warehouse operations and provide real-time visibility into inventory and warehouse processes.`,
    faq: [
      "Students and Graduates",
      "Warehouse and Logistics Professionals",
      "IT Professionals and SAP Consultants",
      "Supply Chain Managers",
    ],
    firstColumnData: [
      "Warehouse Stock Management",
      "Warehouse Document Types",
      "Inbound and Outbound Processing",
      "Put-Away Processing",
      "Internal and Multi-step Warehouse Movements",
      "Physical Inventory Process",
      "Warehouse Monitoring and Reporting",
      "Exception Handling",
      "Cross Docking",
    ],

    secondColumnData: [
      "Yard Management",
      "Automatic Configuration",
      {
        title: "Real time Project Specific",
        style: { color: "#0769b4" },
      },
      {
        title: "SAP S4 HANA and Fiori app Overview",
        subcard: true,
      },
      {
        title: "SAP ECC Vs SAP S4 HANA",
        subcard: true,
      },
      {
        title: "SPRO Path Vs Easy Access Path",
        subcard: true,
      },
      {
        title: "List of SAP Projects Overview",
        subcard: true,
      },
      {
        title: "Project Methodology",
        subcard: true,
      },
      {
        title: "TR Movements",
        subcard: true,
      },
      {
        title: "Different Types of Testing",
        subcard: true,
      },
      {
        title: "Prepare and Execute Test Scripts",
        subcard: true,
      },
      {
        title: "Interfaces and Enhancements",
        subcard: true,
      },
      {
        title: "Technical Terminologies",
        subcard: true,
      },
    ],
  },
  {
    path: "/saptm",
    title: "Rise Infotech | SAP TM",
    description:
      "It consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[4].title} ${SAP.sapFunctionalModules[4].subheading} `,
    whatIsModuleTitle: "What is SAP-TM ? ",
    whatsIsModuleImage: "/assets/images/coachs/tmPage.jpg",
    whatIsModuleDescription:
      "SAP TM (Transportation Management) is a comprehensive solution designed to manage and optimize the transportation processes within a supply chain. It is part of SAP’s Supply Chain Management suite and provides extensive features for planning, optimizing, and executing transportation processes.",
    faq: [
      "Logistics and Transportation Professionals",
      "IT Professionals and System Administrators",
      "Business Analysts",
      "Project Managers/ Operations Managers",
      "Finance and Accounting Professionals",
      "Students and Graduates",
    ],
    firstColumnData: [
      "SAP ERP Overview",
      "SAP TM Module Introduction",
      "SAP Architecture",
      "SAP Functional and Technical Module Overview",
      "SAP System landscape",
      "Enterprise Structure",
      "Master Data Management for SAP TM",
      "Freight Units and Freight Order Management",
      "Supplier Freight Invoice Request",
      "Executing Freight Orders",
      "Planning and Carrier Process",
      "Charge Management",
      "TM Configurations",
      "Business Process Application",
      "Translation of Business Requirements into SAP TM Configuration",
    ],

    secondColumnData: [
      "Settlement management",
      "Cross Functional module Integration with MM, SD and FICO",
      {
        title: "Real time Project Specific",
        style: { color: "#0769b4" },
      },
      {
        title: "SAP S4 HANA and Fiori app Overview",
        subcard: true,
      },
      { title: "SAP S4 HANA and Fiori app Overview", subcard: true },
      { title: "SAP ECC Vs SAP S4 HANA", subcard: true },
      { title: "SPRO Path Vs Easy Access Path", subcard: true },
      { title: "List of SAP Projects overview", subcard: true },
      { title: "Project Methodology", subcard: true },
      { title: "TR movements", subcard: true },
      { title: "Different types of Testing", subcard: true },
      { title: "Prepare and Execute Test Scripts", subcard: true },
      { title: "Interfaces and Enhancements", subcard: true },
      { title: "Technical terminologies", subcard: true },
    ],
  },
  {
    path: "/sappp",
    title: "SAP Production Planning (PP)",
    description:
      "SAP Production Planning (PP) is a key module within the SAP ERP system that focuses on the planning and management of production processes. It is designed to integrate various production-related activities, streamline manufacturing operations, and optimize the use of resources.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[5].title} ${SAP.sapFunctionalModules[5].subheading} `,
    whatIsModuleTitle: "What is SAP-PP ? ",
    whatsIsModuleImage: "/assets/images/coachs/ppPage.jpg",
    whatIsModuleDescription: `SAP Production Planning (PP) is a key module within the SAP ERP system that focuses on the planning and management of production processes. It is designed to integrate various production-related activities, streamline manufacturing operations, and optimize the use of resources.`,
    faq: [
      "Production Planners and Managers",
      "Manufacturing Professionals",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "SAP ERP Overview",
      "SAP-PP module Introduction",
      "SAP System landscape",
      "SAP Architecture",
      "SAP Functional and Technical modules overview",
      "SAP-PP Organisational Structure",
      "List of Master data",
      "Production planning Cycle",
      "Demand Management",
      "Make to Stock and Make to order",
      "Material requirements Planning (MRP) Overview",
      "Capacity planning and Levelling",
      "Long term planning (LTP)",
      "Production Orders, confirmation and Close",
    ],

    secondColumnData: [
      "Yard Management",
      "Automatic Configuration",
      {
        title: "Real time Project Specific",
        style: { color: "#0769b4" },
      },
      {
        title: "SAP S4 HANA and Fiori app Overview",
        subcard: true,
      },
      {
        title: "SAP ECC Vs SAP S4 HANA",
        subcard: true,
      },
      {
        title: "SPRO Path Vs Easy Access Path",
        subcard: true,
      },
      {
        title: "List of SAP Projects Overview",
        subcard: true,
      },
      {
        title: "Project Methodology",
        subcard: true,
      },
      {
        title: "TR Movements",
        subcard: true,
      },
      {
        title: "Different Types of Testing",
        subcard: true,
      },
      {
        title: "Prepare and Execute Test Scripts",
        subcard: true,
      },
      {
        title: "Interfaces and Enhancements",
        subcard: true,
      },
      {
        title: "Technical Terminologies",
        subcard: true,
      },
    ],
  },
  {
    path: "/sapqm",
    title: "Rise Infotech | SAP QM",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords: [
      "SAP Materials management",
      "SAP Warehouse management",
      "SAP Advance business application programming",
      "SAP Extended Warehouse management",
      "SAP Finance and Controlling",
    ],
    heading: `${SAP.sapFunctionalModules[6].title} ${SAP.sapFunctionalModules[6].subheading} `,
    whatIsModuleTitle: "SAP-QM",
    whatsIsModuleImage: "/assets/images/coachs/qm-new.jpg",
    whatIsModuleDescription:
      "SAP Quality Management (QM) is a module within the SAP ERP system that supports quality planning, quality assurance, and quality control activities in an organization. It integrates various quality processes across business functions to ensure that products meet defined quality standards and comply with regulatory requirements.",
    faq: [
      "Quality Managers and Engineers",
      "Quality Assurance Professionals",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Quality Planning",
      "Quality Inspection",
      "Quality Notifications and Defect Management",
      "Quality Certificates and Documentation",
      "Supplier Quality Management",
      "Audit Management",
      "Integration with Other SAP Modules",
    ],
  },
  {
    path: "/sappm",
    title: "Rise Infotech | SAP PM",
    description:
      "It consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[7].title} ${SAP.sapFunctionalModules[7].subheading} `,
    whatIsModuleTitle: "What is SAP-PM ? ",
    whatsIsModuleImage: "/assets/images/coachs/pmPage.jpg",
    whatIsModuleDescription:
      "SAP Plant Maintenance (PM) is a module within the SAP ERP system that focuses on managing maintenance activities in an organization's plants or facilities. It supports the efficient planning, execution, and monitoring of all maintenance tasks, ensuring that assets are kept in optimal working condition to minimize downtime and maximize productivity.",
    faq: [
      "Maintenance Managers and Technicians",
      "Plant Engineers",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Equipment and Technical Objects Management",
      "Maintenance Planning",
      "Work Order Management",
      "Scheduling and Resource Management",
      "Maintenance Execution/ Breakdown Maintenance",
      "Spare Parts Management",
      "Document Management",
      "Reporting and Analytics",
    ],
  },
  {
    path: "/sapps",
    title: "Rise Infotech | SAP PS",
    description:
      "It consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[8].title} ${SAP.sapFunctionalModules[8].subheading} `,
    whatIsModuleTitle: "What is SAP-PS ?",
    whatsIsModuleImage: "/assets/images/coachs/psPage.jpg",
    whatIsModuleDescription:
      "SAP Project System (PS) is a module within the SAP ERP system that supports the planning, execution, and monitoring of projects in organizations. It enables efficient management of projects from inception to completion, including project planning, scheduling, resource allocation, cost management, and reporting.",
    faq: [
      "Project Managers",
      "Project Planners and Coordinators",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Project Planning",
      "Project Execution",
      "Project Cost Management",
      "Integration with Other SAP Modules",
      "Project Reporting and Analytics",
      "Resource Management",
      "Project Integration",
    ],
  },
  {
    path: "/saphr",
    title: "Rise Infotech | SAP HR ",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[9].title} ${SAP.sapFunctionalModules[9].subheading} `,
    whatIsModuleTitle: "What is SAP-HR/HCM ? ",
    whatsIsModuleImage: "/assets/images/coachs/hrPage.jpg",
    whatIsModuleDescription:
      "SAP HR/HCM encompasses various sub-modules that manage different aspects of human resource management, from recruitment and employee data management to payroll and performance evaluation.",
    faq: [
      "HR/HCM Professionals",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Organizational Management",
      "Personnel Administration",
      "Recruitment and Talent Management",
      "Time Management/ Payroll Management",
      "Employee Self-Service (ESS) and Manager Self-Service (MSS)",
      "Performance and Goal Management/ Compensation Management",
      "Learning and Development",
      "HR Reporting and Analytics",
    ],
  },
  {
    path: "/sapsrm",
    title: "Rise Infotech | SAP SRM",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[10].title} ${SAP.sapFunctionalModules[10].subheading} `,
    whatIsModuleTitle: "What is SAP-SRM ? ",
    whatsIsModuleImage: "/assets/images/coachs/srmPage.jpg",
    whatIsModuleDescription:
      "SAP Supplier Relationship Management (SRM) is a comprehensive module designed to manage and optimize the procurement and supplier management processes of an organization. It facilitates better collaboration with suppliers, enhances procurement efficiency, and helps achieve cost savings through effective supplier relationship management.",
    faq: [
      "Procurement Professionals",
      "Supplier Relationship Managers",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Supplier Management",
      "Procurement Planning",
      "Operational Procurement",
      "Procurement Analytics",
      "Procurement Processes Integration",
      "Self-Service Procurement",
      "Collaboration and Communication",
      "Compliance and Risk Management",
    ],
  },
  {
    path: "/sapcrm",
    title: "Rise Infotech | SAP EWM",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[11].title} ${SAP.sapFunctionalModules[11].subheading} `,
    whatIsModuleTitle: "What is SAP-CRM ? ",
    whatsIsModuleImage: "/assets/images/coachs/crmPage.jpg",
    whatIsModuleDescription: `SAP Customer Relationship Management (CRM) is a comprehensive module designed to manage and enhance a company's interactions with its customers and prospects. It supports various business processes such as marketing, sales, service, and customer analytics, enabling organizations to build strong customer relationships, improve customer satisfaction, and drive sales growth.`,
    faq: [
      "Sales and Marketing Professionals",
      "Customer Service Managers",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Marketing Management",
      "Sales Management",
      "Service Management",
      "Customer Interaction Centre (CIC)",
      "Partner Channel Management",
      "Customer Analytics",
      "Integration with Other SAP Modules",
    ],
  },
  {
    path: "/sapsrm",
    title: "Rise Infotech | SAP SRM",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[10].title} ${SAP.sapFunctionalModules[10].subheading} `,
    whatIsModuleTitle: "What is SAP-SRM ? ",
    whatsIsModuleImage: "/assets/images/coachs/srmPage.jpg",
    whatIsModuleDescription:
      "SAP Supplier Relationship Management (SRM) is a comprehensive module designed to manage and optimize the procurement and supplier management processes of an organization. It facilitates better collaboration with suppliers, enhances procurement efficiency, and helps achieve cost savings through effective supplier relationship management.",
    faq: [
      "Procurement Professionals",
      "Supplier Relationship Managers",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Supplier Management",
      "Procurement Planning",
      "Operational Procurement",
      "Procurement Analytics",
      "Procurement Processes Integration",
      "Self-Service Procurement",
      "Collaboration and Communication",
      "Compliance and Risk Management",
    ],
  },
  {
    path: "/sapplm",
    title: "SAP Product Lifecycle Management (PLM)",
    description:
      "SAP Product Lifecycle Management (PLM) is a comprehensive module within the SAP ERP system designed to manage the entire lifecycle of a product from inception, through design and manufacturing, to service and disposal. It integrates all the information and processes related to the product across different departments and stages, enabling organizations to innovate efficiently, bring products to market faster, and manage product-related information effectively.",

    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[12].title} ${SAP.sapFunctionalModules[12].subheading} `,
    whatIsModuleTitle: "What is SAP-PLM ? ",
    whatsIsModuleImage: "/assets/images/coachs/plmPage.jpg",
    whatIsModuleDescription: `SAP Product Lifecycle Management (PLM) is a comprehensive module within the SAP ERP system designed to manage the entire lifecycle of a product from inception, through design and manufacturing, to service and disposal. It integrates all the information and processes related to the product across different departments and stages, enabling organizations to innovate efficiently, bring products to market faster, and manage product-related information effectively.`,
    faq: [
      "Product Managers",
      "Design and Engineering Professionals",
      "SAP Consultants",
      "Project Managers",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Product Data Management (PDM)",
      "Product Development and Design",
      "Process Management",
      "Product Lifecycle Data Analytics",
      "Regulatory and Compliance Management",
      "Maintenance and Service Management",
      "Integration with Other SAP Modules",
    ],
  },
  {
    path: "/sapariba",
    title: "SAP Product Lifecycle Management (PLM)",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",

    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapFunctionalModules[13].title} ${SAP.sapFunctionalModules[13].subheading} `,
    whatIsModuleTitle: "What is SAP-ARIBA ? ",
    whatsIsModuleImage: "/assets/images/coachs/ARIBA.jpg",
    whatIsModuleDescription: `SAP-Ariba is a procurement platform that connects buyers and suppliers globally, facilitating efficient and transparent procurement processes. It offers tools for sourcing, procurement, supplier management, contract management, and spend analysis, helping organizations streamline their procurement operations, reduce costs, and improve supplier collaboration.`,
    faq: [
      "Procurement Professionals",
      "Supply Chain Professionals",
      "Finance and Accounting Professionals",
      "IT Professionals",
      "Business Analysts",
      "Executives and Decision-Makers",
      "Students and Graduates",
    ],
    firstColumnData: [
      "SAP Ariba Sourcing",
      "SAP Ariba Discovery",
      "SAP Ariba Buying and Invoicing",
      "SAP Ariba Contract Management",
      "SAP Ariba Spend Analysis",
      "SAP Ariba Supplier Risk",
      "SAP Ariba Supplier Lifecycle and Performance",
      "SAP Ariba Invoice Management",
    ],
  },
  {
    path: "/sapabap",
    title: "Rise Infotech | SAP ABAP",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",

    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapTechnicalModules[0].title} ${SAP.sapTechnicalModules[0].subheading} `,
    whatIsModuleTitle: "What is SAP-ABAP ? ",
    whatsIsModuleImage: "/assets/images/coachs/abapPage.jpg",
    whatIsModuleDescription: `SAP ABAP (Advanced Business Application Programming) is a high-level programming language created by SAP SE for developing applications on the SAP platform. ABAP is the primary language used for programming the SAP R/3 system, which includes modules such as Financial Accounting (FI), Sales and Distribution (SD), and Materials Management (MM). It allows developers to create custom reports, interfaces, forms, workflows, and enhancements tailored to the specific needs of an organization.`,
    faq: [
      "Software Developers",
      "SAP Consultants",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Development Environment",
      "Data Dictionary",
      "Programming Constructs",
      "Enhancements and Modifications",
      "Forms and Outputs",
      "Object-Oriented Programming (OOP)",
      "Database Access",
      "Integration and Communication",
      "Debugging and Testing",
    ],
  },
  {
    path: "/sapbtp",
    title: "Rise Infotech | SAP-BTP",
    description: "",
    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
      heading: `${SAP.sapTechnicalModules[4].title} ${SAP.sapTechnicalModules[4].subheading} `,
    whatIsModuleTitle: "What is SAP-BTP?",
    whatsIsModuleImage: "/assets/images/coachs/btp.jpg",
    whatIsModuleDescription:
      "SAP BTP (Business Technology Platform) is SAP's integrated offering designed to help organizations transform their business processes by leveraging cloud technologies. It's a comprehensive suite of tools, services, and solutions that enable companies to innovate, integrate, and extend their SAP and third-party applications in the cloud",
    faq: [
      "IT Professionals and System Administrators",
      "Software Developers and Application Architects",
      "Data Analysts and Data Scientists",
      "Business Analysts and Consultants",
      "SAP Consultants",
      "Project Managers in IT and Digital Transformation",
      "Students and New Graduates in IT and Computer Science",
      "Entrepreneurs and Tech Startups",
      "Digital Transformation Leaders",
      "SAP End Users with Advanced Requirements",
    ],
    firstColumnData : [
      {
        "title": "Introduction to SAP BTP",
        "subItems": ["Overview of SAP BTP", "SAP BTP Architecture"]
      },
      {
        "title": "Database and Data Management",
        "subItems": [
          "SAP HANA Cloud",
          "SAP Data Warehouse Cloud",
          "SAP Data Intelligence"
        ]
      },
      {
        "title": "Application Development",
        "subItems": [
          "SAP Business Application Studio",
          "SAP Extension Suite",
          "Low-Code/No-Code Development"
        ]
      },
      {
        "title": "Integration",
        "subItems": ["SAP Integration Suite", "Process Integration"]
      },
      {
        "title": "Security and Compliance",
        "subItems": ["Security Best Practices", "Compliance"]
      },
      {
        "title": "DevOps and CI/CD",
        "subItems": [
          "Continuous Integration/Continuous Deployment (CI/CD)",
          "DevOps Practices"
        ]
      },
      {
        "title": "Intelligent Technologies",
        "subItems": ["Internet of Things (IoT)", "Robotic Process Automation (RPA)"]
      },
      {
        "title": "Multi-Cloud and Hybrid Deployments",
        "subItems": ["Deploying Applications", "Hybrid Cloud Architecture"]
      },
      {
        "title": "Real-World Use Cases and Projects – Hands on projects and case studies"
      }
    ]    
  },
  {
    path: "/sapbasis",
    title: "Rise Infotech | SAP-BASIS",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",

    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapTechnicalModules[1].title} ${SAP.sapTechnicalModules[1].subheading} `,
    whatIsModuleTitle: "What is SAP-BASIS ? ",
    whatsIsModuleImage: "/assets/images/coachs/basisPage.jpg",
    whatIsModuleDescription: `SAP Basis is the technical foundation that enables SAP applications to function smoothly. It includes middleware programs and tools that support the interoperability and portability of SAP applications across systems and databases. SAP Basis is essential for the administration of the SAP environment, encompassing activities such as system installation, configuration, load balancing, and performance tuning.`,
    faq: [
      "System Administrators",
      "SAP Consultants",
      "Database Administrators",
      "Network Administrators",
      "IT Support Staff",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Application and Database Administration",
      "Performance Tuning and Optimization",
      "System Security",
      "Patch and Upgrade Management",
      "Backup and Recovery",
      "Interface Management",
      "System Landscape Design and Management",
      "Software installation",
      "OS/DB Migration",
      "Upgrade",
      "Hardening - Improving system security",
      "System monitoring",
      "Root cause analysis",
    ],
  },
  {
    path: "/sapsecurity",
    title: "Rise Infotech | SAP SECURITY",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",

    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapTechnicalModules[2].title} ${SAP.sapTechnicalModules[2].subheading} `,
    whatIsModuleTitle: "What is SAP-SECURITY/GRC ? ",
    whatsIsModuleImage: "/assets/images/coachs/securityPage.jpg",
    whatIsModuleDescription: `SAP Security is a critical aspect of managing and protecting SAP systems. It involves safeguarding the SAP environment against unauthorized access and ensuring compliance with security policies and regulations. SAP Security encompasses various tasks, including user administration, role and authorization management, system monitoring, and audit logging.`,
    faq: [
      "System Administrators",
      "SAP Consultants",
      "Security Analysts",
      "Auditors and Compliance Officers",
      "IT Support Staff",
      "Students and Graduates",
    ],
    firstColumnData: [
      "User Administration",
      "Role and Authorization Management",
      "System Security",
      "Audit and Compliance",
      "Data Security",
      "Security Monitoring and Incident Response",
      "Access Control",
      "Integration with External Security Solutions",
    ],
  },
  {
    path: "/sapbibw",
    title: "Rise Infotech | SAP BI/BW",
    description:
      "Web site created using create-react-appIt consists of all the key modules under SAP ERP Business Suite - Material Management, Supply Chain, Demand Planning, Sourcing and Procurement, Contract Management, and Manufacturing.",

    keywords:
      "SAP Materials management, SAP Warehouse management, SAP Advance business application programming, SAP Extended Warehouse management, SAP Finance and Controlling",
    heading: `${SAP.sapTechnicalModules[3].title} ${SAP.sapTechnicalModules[3].subheading} `,
    whatIsModuleTitle: "What is SAP-BI/BW ? ",
    whatsIsModuleImage: "/assets/images/coachs/bibwPage.jpg",
    whatIsModuleDescription: `SAP Business Warehouse (BW) is a comprehensive data warehousing and reporting tool designed to facilitate decision-making processes by consolidating, transforming, and managing data from various sources. It provides a platform for data modeling, warehousing, and analysis, enabling organizations to gain insights from their data and support business intelligence (BI) initiatives.`,
    faq: [
      "Data Analysts and Business Intelligence Professionals",
      "SAP Consultants",
      "Database Administrators",
      "Business Analysts",
      "Students and Graduates",
    ],
    firstColumnData: [
      "Data Warehousing",
      "Data Modeling",
      "ETL (Extract, Transform, Load) Process",
      "Reporting and Analysis",
      "Integration with SAP HANA",
      "Data Archiving",
      "Performance Optimization",
      "Security and Authorization",
      "Planning and Forecasting",
    ],
  },
];
